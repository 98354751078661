<template>
  <div>
    <p class="has-text-weight-bold is-size-4 mb-1">
      {{ $t("af:application.under_review.title") }}
    </p>
    <p class="mb-5">{{ $t("af:application.under_review.subtitle") }}</p>
    <application-template template="IN_REVIEW"></application-template>
  </div>
</template>

<script>
export default {
  name: "ApplicationFlowUnderReview",
  components: {
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  props: {},
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
